.forgot-password-form{
    width: 100vw;
    height: 100vh;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot-password-input{
    width:20rem;
}
.forgot-password-submit-button{
    width: 12rem;
    margin: 1rem;
    height:3rem;
    color: white;
    background-color: #874B9E;
    border:transparent;
    border-radius: 5px;
    font-size: 1rem;
}