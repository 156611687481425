.Footer{
    bottom: -18rem;
    height: 18rem;
    box-sizing: border-box;
    left: 0;
    background-color: white;
    position: sticky;
    width: calc(100% + 2.5rem);
    transform: translate(-1.25rem, 2rem);
    padding-left: 3rem;
    padding-right: 3rem;
}

.Footer-Top-Half{
    
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: calc(2px + 1.5vw); 
    align-items: center;
    flex-direction: row;
    align-items: flex-start;    
}

.Footer-Bottom-Half{
    height: 6rem;
    border-top: 1px solid #4F5962;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    flex-direction: row;
    align-items: center;
}

.Footer-Section{
    width: calc(100vw/5);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Footer-Section-Header{
    font-size: 1rem;
    margin-left: 1rem;
    color: #4F5962 ;
    margin-bottom: 0px;
}
.Footer-Section-P{
    color: #4F5962 ;
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: .25rem;
    margin-bottom: 0px;
    padding-right: 0px;
    height: 1rem;   
}
.clickable{
    cursor: pointer;
    display: inline-block;
    width:max-content;
}

.grey-logo{
    cursor: pointer;
    height: 2rem;
    opacity: 60%;
    
}

@media (max-width: 1350px){
    .Videographers{
        display: none;
    }
    .Footer-Section{
        width: 100%;
    }
}

@media(max-width:1080px){
    .Businesses{
        display: none;
    }
}

@media (max-width: 968px){
    .Footer-Top-Half{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .Footer{
        position: sticky;
        width: 100vw;
        transform: translate(-1.25rem, 2rem);
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media(max-width:610px){
    .Subtitle{
        opacity: 0%;
    }
}

@media(max-width: 470px){
    .NXTVid{
        display: none;
    }
    .Footer-Section{
        text-align: center;
        align-items: center;
    }
    .Footer-Section-P,
    .Footer-Section-Header{
        margin-left:0;
    }
}
@media(max-width:418px){
    .grey-logo{
        display: none;
    }
    .Footer-Bottom-Half{
        justify-content: center;
    }
}

