.modal{
    z-index: 16 !important;
    background-color: white;
    height: 10rem;
    width: 25rem;
    border-radius: 10px;
    padding: 1rem;
    justify-content: center;
    border: 1px solid #4e82af52;
    position: fixed;
    max-width: 90vw;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    top: 10rem;
}
.modal > button{
    margin: 1rem;
    width: 6rem;
    height: 3rem;
    color: white;
    font-weight: bold;
    background-color: #874B9E;
    border: none;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 5px;
}