.sign-in-card,
.sign-up-card,
.forgot-password-card,
.purchase-tokens-card{
  background-color: white;
  width: 30rem;
  height: 42.5rem;
  border-radius: 1.5rem;
  position: fixed;
  right: calc(50vw - 30em / 2);
  display: none;
  text-align: left;
  color: #4F5962;
  z-index: 20;
  transform: translateY(0rem);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.purchase-tokens-packages{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchase-tokens-package{
    height: 7.5rem;
    width: 90%;
    border: 1px solid #4e82af52;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-bottom: 1rem;
}

.tokens-image{
    height: 5rem;
    margin-left: 1rem;
}

.tokens-amount{
    font-size: 2.5rem;
    margin: 0;
    color: #874B9E;
    font-weight: bold;
}

.tokens-name{
    font-size: 1.25rem;
    margin: 0;
    margin-top: .1rem;
    
}

.package-details-section{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.package-details{
    text-align: left;
    margin-left: 1rem;
}

.package-purchase-section{
    text-align: right;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.package-price{
    font-size: 1.25rem;
    margin: 0;
    
}

.purchase-package-button{
    background-color: #874B9E;
    color: white;
    border: none;
    height: 2.5rem;
    width: 6rem;
    font-size: 1rem;
    border-radius: .25rem;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sign-in-card{
    height: 25rem;
}

.forgot-password-card{
    height: 20rem;
}

.colored-purple{
    color: #874B9E;
}

.sign-in-card-header,
.sign-up-card-header{
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.sign-in-card-title,
.sign-up-card-title,
.purchase-tokens-card-title{
    font-size: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    font-weight: bold;
}

.purchase-tokens-card-title{
    font-size: 1.75rem;
}

.sign-up-card-sub-title{
    font-size: 1.25rem;
    margin-top: .5rem;
    margin-bottom: 0rem;
}

.sign-in-card-details,
.sign-up-card-details{
    margin-top: .6rem;
    font-size: .8rem;
    display: inline;
}

.sign-in-card-link,
.sign-up-card-link{
    margin-top: .5rem;
    font-size: .8rem;
    display: inline-block;
    margin-left:.3rem ;
    color: #874B9E;
    cursor: pointer;
}

.sign-up-input-section-first-name,
.sign-up-input-section-last-name,
.sign-up-input-section-company,
.sign-up-input-section-email,
.sign-up-input-section-password{
    margin-left: 2rem;
    font-weight: bold;
    padding: 0;
    margin-bottom: 2rem;
}

.sign-up-input{
    width: 26rem;
    font-size: 1rem;
    margin-top: 0px;
}

.sign-up-input-label{
    margin: 0;
}

.sign-up-button{
    width: 26rem;
    margin-left: 2rem;
    cursor: pointer;
    height: 4rem;
    background-color: #874B9E;
    color: white;
    font-size: 1.15rem;
    border: 0px;
    border-radius: .35rem;
}

.exit-button{
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    width: 1.3rem;
    cursor: pointer;
}

.sign-up-notification-message,
.sign-in-notification-message{
    position: absolute;
    top: 4.8rem;
    font-size: .75rem;
    color: #6DBF67;
    font-style: italic;
}

.forgot-password{
    font-size: .75rem;
    position: absolute;
    top: 16rem;
    cursor: pointer;
    color: #874B9E;
    font-weight: normal;
}
@media (max-width: 765px) {
    .sign-in-card,
    .sign-up-card,
    .forgot-password-card,
    .purchase-tokens-card{
        background-color: white;
        width: 100vw;
        border-radius: 0rem;
        position:fixed;
        right: 0px;
        display: none;
        text-align: left;
        color: #4F5962;
        z-index: 20;
        top: 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        height: 100vh;
    }
    .sign-up-button{
        width: calc(100vw - 4rem);
        margin-left: 2rem;
        cursor: pointer;
    }
    .sign-up-input{
        width: calc(100vw - 4rem);
        
    }
  }
@media (max-width: 430px) {
    .purchase-tokens-card-title{
        font-size: 1.5rem;
        margin-top: 2.75rem;
    }
    .tokens-name{
        display: none;
    }
    .tokens-image{
        height: 20vw;
    }
    .tokens-amount{
        font-size: 8vw;
    }
  }

  @media (max-height: 790px){
    .purchase-tokens-card{
        background-color: white;
        width: 100vw;
        border-radius: 0rem;
        position:fixed;
        right: 0px;
        display: none;
        text-align: left;
        color: #4F5962;
        z-index: 20;
        top: 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        height: 100vh;
    }
  }