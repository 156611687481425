.App-header {
    background-color: white;
    padding: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: calc(2px + 1.5vw);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); 
    color: #874B9E;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 18;
    box-sizing: border-box;
    height: 6rem;
  }

.Logo{
    height: calc(10px + 4vw);
    margin-left: calc(10px + 3vw);
    max-height: 3.5rem;
  }

.NavBar{
    text-align: right;
    display: flex;
    padding-right: calc(10px + 4vw);
    align-items: center;
    
  }
  
  .NavBar-Item{
    color: #4F5962 ;
    font-weight: bold;
    margin-right: 2rem;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .masthead-button{
    height: 50px;
    cursor: pointer;
  }
  
  .grey-carrot{
    height: 20px;
    display: inline;
    padding-left: 3px;
  }
  
  .hamburger-menu{
    display: none;
  }
  
  .mobile-NavBar{
    display: none;
  }
  
  .product-dropdown {
    display: flex;
    align-items: center;
    position: relative; 
  }
  
  .product-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 100%;
    left: 0;
    min-width: 100%;
    font-size: .8rem;
    color: #4f5962b2 ;
  }
  
  .product-dropdown-content p {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    
  }
  
  .product-dropdown-content p:hover {
    background-color: #f1f1f1;
    color: #4F5962 ;
  }
  
  .product-dropdown:hover .product-dropdown-content {
    display: block;
  }
  a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Inherits the color from the parent element */
    cursor: pointer; /* Ensures the cursor changes to a pointer */
    }
    

.profileImage{
    background-color: #874B9E;
    width: calc(20px + 4vw);
    height: calc(20px + 4vw);
    border-radius: 50px;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 23px;
    max-height: 4rem;
    max-width: 4rem;
    cursor: pointer;
}

.sign-out-button{
  height: 3rem;
  width: 7.5rem;
  background-color: white;
  color: #4f5962b2;
  position: absolute;
  top: 4.5rem;
  right: 5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  font-size: 1rem;
  display: none;
}

.sign-out-button p:hover {
  background-color: #f1f1f1;
  color: #4F5962 ;
  padding: 8px;
}

.tokensIcon{
height: 4rem;
}
  
  @media (max-width: 900px) {
    .NavBar{
      display: none;
    }
    .mobile-nav-left{
      display: flex;
      flex-direction: row;
      margin-left: 3vw;
      align-items: center;
  
    }
    .mobile-nav-right{
      margin-right: 3vw;
    
    }
    .sign-out-button{
      left: 1rem;
      top: 4rem
    }
    .profileImage{
      height: 3.5rem;
      width: 3.5rem;
    }
    .mobile-NavBar{
      display: flex;
      padding-right: calc(10px + 4vmin);
      align-items: center;
    }
    .sliding-menu{
        position: absolute;
        background-color: white;
        width: 100%;
        left: 0;
        top: 100%;
        height: 5rem;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 3;
        position:absolute;
        box-shadow: none;
        justify-content: space-between;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); 
        
    }

    .product-dropdown{
        z-index: 6;
    }

    .tokensContainer{
      transform: unset;
    }

    .App-header::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px; /* Adjust based on shadow size */
    width: 100%;
    height: 10px; /* Adjust based on shadow size */
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25); /* The shadow */
    z-index: 5; /* Keep it behind the header but above sliding-menu */
    pointer-events: none; /* Prevent it from interfering with clicks */
    }
    .NavBar-Item{
        font-size: .9rem; 
        margin:unset;
        margin-left: 2rem;
    }
    .masthead-button{
        height: 45px;
        cursor: pointer;
        z-index: 3;        
    }
    .signup-signin{
        display: flex;
        margin-left: auto;
        margin-right: 50px;
    }
    .hamburger-menu{
        display: block;
        font-size: 2rem;
        margin: 0px;
      }
  
  }
  @media (max-width: 600px) {
    .vanish-2{
        display: none;
    }
  }
 
  