.App-body-home{
  background-color: #E0ECFC;
  display:flex;
  justify-content: center;
  min-height: calc(100vh - 170px);
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
}

.tutorial-section{
  height: 90vh;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  
}
.nxtvid-card-component.nameProjectCard{
  position: absolute;
  width: 30rem;
  height: 10rem;
  z-index: 10;
}
.nameProjectCardChild{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
}
.nameProjectCardChild > h3{
  margin: 0;
}
.nameProjectCardChild > input{
  width: 100%;
  margin:0;
}
.desktopTutorial,
.mobileTutorial{
  max-height: 100%;
  max-width: 100%;
  max-width: 90%;
}
.desktopTutorial{
  display: none;
}
.mobileTutorial{
  transform: translateY(-3rem);
}
.nonauth-body{
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  width: 100%;
  transform: translateY(-8rem);
}

.background-shades{
    width: 100vw;
    height: 100vh;
    background-color: #2438494d;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
    display: none;
}
input{
  margin-top: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font: 1.25em sans-serif;
}

.Homepage-SignUp{
    font-weight: bold;
    margin-right:auto ;
    font-size: 1.25vw;
}

.Homepage-SignIn{
    font-size: 5vw;
    cursor: pointer;
    margin-right: 1rem;
    margin-left: auto;
}
.signup-signin-homepage{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
    margin: .3rem;
}
.see-how-it-works-link{
  color: #874B9E;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  transform: translateY(4rem);
}

.homepage-nonauth-image{
    display: none;
}

.homepage-nonauth-title{
    font-size: 8vw;
    margin: .3rem;
    color: #874B9E;
    font-weight: bold;
}
.homepage-nonauth-subtitle{
    font-size: 6vw;
    margin: .3rem;
}
.homepage-nonauth-leftHalf{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.homepage-nonauth-righHalf{
    text-align: center;
    display: flex;
    display: -webkit-flex;
    justify-content:center;
    flex-direction: column;
    transform: translateY(-3rem);
}
.User-Dashboard-NotSignedIn{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.User-Dashboard{
    width: 100%;
    display:flex;
    justify-content:space-between;
    margin: 0px;
    transform: translateY(-2rem);
    flex-direction: row;
  }

  .Characters-Dashboard{
    text-align: left;
    background-color: rgb(239, 240, 245);
    margin: 1rem;
    width: calc(30vw - 2rem);
    border-radius: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 13rem);
    overflow: hidden;
    min-height: 19.5rem;
    min-width: 22rem;
    
  }

.Storyboard-Dashboard{
    background-color: rgb(239, 240, 245);
    width: calc(70vw - 2rem);
    text-align: left;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 13rem);
    overflow: hidden;
    min-height: 19.5rem;
    
}

.Dashboard-Header{
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-top-left-radius: 10px;
    position: relative;
    z-index: 10px;
    width: 100%;
}

.Dashboard-Header > h3{
    margin-left: 1.5rem;
    font-size: 1.5rem;
  }

  .Dashboard-Header > img{
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
    cursor: pointer;
    margin-right: 1.5rem;
  }

  .characterList,
  .storyboardList{
    overflow-y:auto;
    height: calc(100% - 6rem);
    min-height: 13.5rem;
    
  } 
  
  .storyboardCard,
  .characterCard{
    background-color: white;
    display: flex;
    display: -webkit-flex;
    margin: 1rem;
    padding: 20px;
    height: 10rem;
    justify-content:space-between;
    border: 1px solid #4e82af52;
    border-radius: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .storyboard-options-toggle{
    cursor: pointer;
    font-size: 2rem;
    position: relative;
    bottom: -1rem
  }

  .storyboard-options{
    display: none;
  }
  .characterCard-details,
  .storyboardCard-details{
    font-size: .95rem;
    text-align: left;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 2rem;
    margin-left: 1rem ;
    max-width: calc(70vw - 57rem);
  }

  .characterCard-details{
    height: 8rem;

  }
  
  .storyboardCard-name,
  .characterCard-name{
    font-size: 1.5rem;
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
.edit-storyboard-label{
  cursor: pointer;
}
  .project-title-container{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items:center;
    position: relative;
  }
  .character-name-container{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items:center;
    position: relative;
  }
  .settings-icon{
    margin-left: .5rem;
    height: 1.25rem;
    transform: translateY(.1rem);
    cursor: pointer;
  }

  .settings-container:hover > .storyboard-settings{
    display: flex;
    display: -webkit-flex;
  }

  .settings-container:hover > .character-settings{
    display: flex;
    display: -webkit-flex;
  }

  .storyboard-settings{
    height: auto;
    width: 9rem;
    background-color: white;
    color: #4f5962b2;
    position: absolute;
    top: 1.75rem;
    right: -7.75rem;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    font-size: 1rem;
    display: none;
    z-index: 5;
  }

  .storyboard-setting{
    padding: 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
  }

  .storyboard-setting:hover{
    background-color: #f1f1f1;
    color: #4F5962;
  }

  .character-settings{
    height: 3rem;
    width: 9rem;
    background-color: white;
    color: #4f5962b2;
    position: absolute;
    top: 1.75rem;
    right: -7.75rem;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    font-size: 1rem;
    display: none;
  }

  

  .storyboardCard-detail,
  .characterCard-detail{
    margin:0;
    text-wrap:nowrap;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  }

  .edit-icon{
    height: 1.25rem;
    margin-left: .25rem;
    cursor: pointer;
    
  }
  
  .deleteStoryboardButton,
  .editStoryboardButton,
  .deleteCharacterButton{
    cursor: pointer;
    background-color: #874B9E;
    color: white;
    font-size: 1rem;
    height: 2rem;
    border-radius: .3rem;
    border-color: transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 10rem;
  }

  .deleteStoryboardButton,
  .deleteCharacterButton{
    background-color: #DF7C52
  }

  
  .storyboardCard-img,
  .characterCard-img{
    border-radius: .6rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 2rem;
    width: auto;
    height: auto;
  }

  .storyboardCard-img{
    max-width: 100%;
    aspect-ratio: 16/12;
    flex: 0 1 auto;
    max-height: 100%;
  }

  .characterCard-img{
    aspect-ratio: 9/16;
    flex: 0 1 auto;
    max-height: 100%;
  }

  .characterCard-img-container{
    margin-right: 1rem;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center; 
  }

  .storyboardCard-imgs{
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    max-height: 10rem;
    align-items: center;
  }

  .storyboardCard-img-label{
    transform: translateY(-5px);
    font-size: .9rem;
  }

  .rightButton,
  .leftButton{
      background-color: white;
      height: 3rem;
      width: 3rem;
      z-index: 6;
      border-radius: 2rem;
      cursor: pointer;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      align-items: center;
      
  }
  
  .rightButton{
    transform: translate(-3rem, -3rem);
  }
  .leftButton{
    transform: translate(3rem, -3rem);
  }
  
  .rightButton > img{
      transform: rotate(-90deg) translateY(.15rem);
      height: 3rem;
  }
  
  .leftButton > img{
      transform: rotate(90deg) translateY(.15rem);
      height: 3rem;
  }

@media (orientation: landscape){
    .homepage-nonauth-image{
        max-width: 45vw;
        display: block;
    }
    .desktopTutorial{
      display: flex;
      display: -webkit-flex;
    }
    .mobileTutorial{
      display: none;
    }
    .User-Dashboard-NotSignedIn{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100vh;
    }
    .nonauth-body{
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      width: 100%;
      transform: translateY(-5.5rem);
    }
    .homepage-nonauth-title{
        font-size: 4vw;
        color: #874B9E;
        font-weight: bold;
    }
    .homepage-nonauth-subtitle{
        font-size: 2vw;
    }
    .homepage-nonauth-righHalf{
        max-width: 45vw;
        text-align: left;
        display: flex;
        display: -webkit-flex;
        justify-content:center;
        flex-direction: column;
    }
    .Homepage-SignUp.nxtvid-button-component{
        width: 8vw;
        cursor: pointer;
        margin: 1rem;
        height: 3vw;
        font-size: 1.25vw !important;
    }
    .Homepage-SignIn{
        font-size: 1.25vw;
        cursor: pointer;
        margin-right: 1rem;
        margin-left: auto;
    }
    .see-how-it-works-link{
      color: #874B9E;
      margin-right: auto;
      margin-left: 0rem;
      position: relative;
      transform: translateY(0rem);
    }
    .signup-signin-homepage{
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width:1650px){
  .index3{
    display: none;
  }
  .storyboardCard-details{
    max-width: calc(70vw - 42rem);
  }
  .characterCard-details{
    max-width: calc(30vw - 17rem);
  }
  .characterCard-detail{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0px;
  }
  
}

@media (max-width:1296px){
  .index2{
    display: none;
  }
  .storyboardCard-details{
    max-width: calc(100vw - 50rem);
  }
  .characterCard-details{
    max-width: 8rem;
  }
}

@media (max-width:968px){
  .User-Dashboard{
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .Storyboard-Dashboard,
  .Characters-Dashboard{
    width: calc(100vw - 2rem);
    height: 30rem;
  }
  
  .index2{
    display: block;
  }
  .storyboardCard-details{
    max-width: calc(100vw - 40rem);
  }
  .characterCard-details{
    max-width: calc(100vw - 10rem);
  }
  .characterCard-img-container{
    width: 7.5rem;
  }
}

@media (max-width:770px){
  .index2{
    display: none;
  }
  .storyboardCard-details{
    max-width: calc(100vw - 23rem);
  }
  
}
@media (max-width:560px){
  .tutorial-section{
    max-width: 100vw;
    margin: 0;
    transform: translate(-2rem);
  }
  
}
@media (max-width:500px){
.storyboardCard-imgs{
  display: none;
}

.storyboardCard-details{
  max-width: unset;
}
.storyboardCard{
  height: 5rem;
}
.editStoryboardButton,
.deleteStoryboardButton{
  margin-left: auto;
  transform: translateY(-4.5rem);
}
.project-title-container{
  max-width: calc(100vw - 9rem);
}
.storyboard-settings{
  right: 0rem
}
.character-settings{
  right:-3.75rem
}
}

@media (max-width:433px){
  .characterCard-details{
    max-width: calc(100vw - 15rem);
  }
  
  .Characters-Dashboard,
  .characterList,
  .characterCard-details{
    min-width: unset;
  }
  }
