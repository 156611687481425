.Button-Component{
    border-radius: 6px;
    height: 3.3rem;
    font-size: 18px;
    background-color: #874B9E;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    border-color: transparent;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.Button-Text{
    margin-right: .5rem;
    margin-left:.5rem;
    margin-top: auto;
    margin-bottom: auto;
}

.Button-Component:disabled{
    background-color: #4f596293;
    border-color: transparent;
    cursor:not-allowed;
}

