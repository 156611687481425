.App {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #E0ECFC;
    padding-top: 150px;
    color: #4F5962;
    background-size: cover;
  }
  
  textarea{
    resize: none;
  }

.background-shade{
    width: 100vw;
    height: 100vh;
    background-color: #2438494d;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
    display: none;
}

.Storyboard-Panel{
    background-color: white;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: calc((100vw)/3 - 3.75rem);
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    height: calc(22.5vw + 25rem);
    transition: transform ease 3s;
    position:relative;
    z-index: 10;
    cursor: grab;
}

.storyboardPage-ProjectTitle{
  position: absolute;
  top: 7rem;
  width: 35vw;
  left:2.5vw;
  font-size: 2rem;
  z-index: 1;
  padding: 10px;
  margin-top: 5px;
  box-sizing: border-box;
  text-align: left;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .Storyboard-Rows{
    display: flex;
    width: 95vw;
    text-align: left;
    margin: 10px;
    
  }
  .Panel-Container{
    display: inline-block;
    transition: transform ease .3s;
  }

  .Panel-Container.over{
    transform: translateX(1rem);
  }

  .Panel-Container.under{
    transform: translateX(-1rem);
  }

  .storyboard{
    position: absolute;
    top: 10rem;
    background-color: #E0ECFC;
    background-size: cover;
    text-align: center;
    width: 100%;
  }

  .Add-Image-Button{
    font-size: 20vw;
    transform: translateY(-3vw);
  }

  .Add-Image-Panel{
    height: calc(22.5vw + 25rem);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }

  .Save-Storyboard-Button{
    border-radius: 6px;
    height: 3.3rem;
    width: 10rem;
    font-size: 18px;
    background-color: #874B9E;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    border-color: transparent;
    cursor: pointer;
    position:absolute;
    top:7.5rem;
    right: 2.5vw;
    z-index: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }

  .Export-Storyboard-Button{
    border-radius: 6px;
    height: 3.3rem;
    width: 11rem;
    font-size: 18px;
    background-color: #874B9E;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    border-color: transparent;
    cursor: pointer;
    position:absolute;
    top:7.5rem;
    right: calc(2.5vw + 11rem);
    z-index: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    
  }

  .Storyboard-Image{
    width: calc((100vw)/3 - 5.75rem);
    margin: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }
  
  .Storyboard-Input{
    margin-top: 15px;
    margin-left: 1rem;
    width: calc((100vw)/3 - 5.75rem);
    z-index: 10;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font: 1.25em sans-serif;
  }

  .Panel-Buttons{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    position:relative;
    flex-direction: row;
    
  }
  .Button-Container2{
    display: flex;
  }

  .settings-icon{
    margin-left: .5rem;
    height: 1.25rem;
    transform: translateY(.1rem);
    cursor: pointer;
  }
  .Tokens-Icon2{
    height: 2.5rem;
    margin-left:1rem
  }
  .Tokens-Amount2{
    font-size: 1rem;
    margin-left: .25rem;
  }
  .settings-container:hover > .panel-settings{
    display: flex;
  }

  .panel-settings{
    height: 3rem;
    width: 9rem;
    background-color: white;
    color: #4f5962b2;
    position: absolute;
    top: 0rem;
    right: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    font-size: 1rem;
    display: none;
  }

.dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: calc((100vw)/3 - 5.75rem);
    margin-top: 15px;
    margin-left: 1rem;
  }
  
  
  .dropdown-label{
    margin-right: .5rem;
    margin-left: .5rem;
    padding-left: 0;
  }
  
  .dropdown-btn {
    background-color:  white;
    color: black;
    padding: 1rem;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    margin-left: auto;
    width: calc((100vw)/3 - 12rem);
    border: 1px solid rgba(211, 211, 211, 0.774);
    border-radius: 5px;
  }
  
  .dropdown-btn:hover {
    background-color: grey;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 12;
    right: 0;
    width: calc((100vw)/3 - 12rem);
    top: 3rem;
    text-align: center;
    max-height: calc(11.5rem);
    overflow-y:scroll;
  }
  
  .dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown-content p:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block ; 
  }

  .dropZone{
    position: absolute;
    transform: translate(-7vw , 1.75rem);
    height: calc(21vw + 25rem);
    width: 10vw;
    z-index: -1;
  }

  .backToDashboardButton-Storyboard{
    height: 3.3rem;
    position: absolute;
    z-index: 15;
    top: 7.5rem;
    left: 38vw;
    cursor: pointer;
    width: 12rem;
    font-size: 1.1rem;
    color: white;
    background-color: #874B9E;
    border: transparent;
    border-radius: .5rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  

  @media(max-width:1249px){
    .Storyboard-Panel{
        width: calc((100vw)/2 - 3.5rem);
        height: calc(32.5vw + 26rem);
    }
    .Storyboard-Image{
      width: calc((100vw)/2 - 5.5rem);
      
    }
    .Storyboard-Input{
      width: calc((100vw)/2 - 5.5rem);
    }
    
    .dropdown{
      width: calc((100vw)/2 - 5.5rem);
    }
    .dropdown-btn {
      width: calc((100vw)/2 - 12rem);
    }
    .dropdown-content{
      width: calc((100vw)/2 - 12rem);
    }
    .storyboardPage-ProjectTitle{
      width: 27.3rem;
      left:1.5rem
    }
    .backToDashboardButton-Storyboard{
      left: 29.5rem;
    }
    .Export-Storyboard-Button,
    .Save-Storyboard-Button{
      width:3.3rem;
      border-radius: 2rem;
      text-align: center;
      right: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Export-Storyboard-Button{
      right:5.5rem;
      
    }
    .Export-Storyboard-Button > img,
    .Save-Storyboard-Button > img{
      height: 1.9rem;
      
    }
    
  }

  @media(max-width:799px){
    .Storyboard-Panel{
      width: calc(100vw - 1.75rem);
      margin-left: .25rem;
      margin-right: 0rem;
        height: calc(68vw + 26rem);
    }
    .Storyboard-Image{
      width: calc(100vw - 3.75rem);

    }
    .Storyboard-Input{
      width: calc(100vw - 3.75rem);
    }
    
    .dropdown{
      width: calc(100vw - 3.75rem);
    }
    .dropdown-btn {
      width: calc(100vw - 11rem);
    }
    .dropdown-content{
      width: calc(100vw - 11rem);
    }
    .Export-Storyboard-Button,
    .Save-Storyboard-Button{
      top:11.5rem;
      left: 14.2rem;
    }
    .Save-Storyboard-Button{
      left:18rem
    }
    .storyboard{
      transform: translateY(4.25rem);
    }
    .backToDashboardButton-Storyboard{
      top:11.5rem;
      left: 1.5rem
    }
  }
  @media(max-width:477px){
    .storyboardPage-ProjectTitle{
      width: calc(100vw - 3rem);
    }
  }  
  
  @media(max-width:355px){
    .backToDashboardButton-Storyboard{
      width:3.3rem;
      border-radius: 2rem;
    }
    .backToDashboardButton-Storyboard > img{
      height: 1.9rem;
      transform: translate(1px,2px);
    }
    .Export-Storyboard-Button{
      left: 5.5rem;
    }
    .Save-Storyboard-Button{
      left:9.5rem
    }
  }

 

  

  