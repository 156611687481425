.App-body {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #E0ECFC;
  padding-top: 150px;
  color: #4F5962;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45rem; /* Full viewport height */
  overflow: hidden; /* Initially prevent scrolling */
}

.background-shades{
    width: 100vw;
    height: 100vh;
    background-color: #2438494d;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;
    
}

.size-warning{
    display: none;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 22;
    top: 0;
    flex-direction: column;
    justify-content:center;
}

.Character-Creator{
    width: calc(100vw - 5rem);
    height: 43rem;
    position: absolute;
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    overflow: hidden;
    top:11rem;
    max-width: 1920px;
  }
  .Character-Creator-Input-Section,
  .Character-Creator-Output-Section {
    display: block;
    position: absolute;
    height: 100%;
    width: 50%;
  }
  
  .Character-Creator-Input-Section {
    border-right: 1px solid #ddd;
    
  }

  .Character-Creator-Input-Section-Div{
    position: absolute;
    top: 4.5rem;
    left: 2.5rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 5rem);
    justify-content: space-between;
    width: calc(((100vw - 14rem) / 2 ));
    max-width: calc(((1920px - 130rem / 2)));
    text-align: left;
  }
  
  .Character-Creator-Output-Section {
    display: block;
    position: absolute;
    right: 0;
  }
  
  .Character-Creator-Header {
    position: absolute; 
    top: .5rem;
    left: 2.5rem;
    font-size: 1.5em;
  }

  .Character-Creator-Output-Preview {
    height: calc(100% - 2.5rem);
    aspect-ratio: 9/16;
    display: block;
    position: absolute;
    left: 50%;
    top: 1.25em;
    max-width: 95%;
    max-height: 1030px;
    border-radius: 10px;
    box-shadow: -2px 8px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%)
  }
  
  .Character-Age-Gender-Ethnicity-Input {
    display: flex;
    justify-content: space-between;
  }
  
  .Character-Age-Gender-Ethnicity-Input > div {
    flex: 1;
    margin-right: 10px;
  }
  
  .Character-Age-Gender-Ethnicity-Input > div:last-child {
    margin-right: 0;
  }

  .backToDashboardButton{
    height: 3.5rem;
    position: absolute;
    z-index: 15;
    top: 7rem;
    left:3rem;
    cursor: pointer;
  }
  .saveCharacterButton{
    height: 3.5rem;
    position: absolute;
    z-index: 15;
    top: 7rem;
    right:3rem;
    cursor: pointer;
  }
  .Tokens-Icon{
    height: 2.5rem;
    margin-left:1rem
  }
  .Button-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
  .Token-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .Tokens-Amount{
    font-size: 1rem;
    margin-left: .25rem;
  }
  .characterInput{
    width: 100%;
    padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font: 1em sans-serif;
  }

  #characterGenderInput,
  #characterEthnicityInput,
  #characterAgeInput{
    max-width: calc((1920px - 12em)/6);
  }

  .toggle-container {
    width: 60px;
    height: 30px;
    background-color: #E0ECFC;
    border-radius: 30px;
    display: none;
    align-self: center;
    justify-content: flex-start;
    padding: 5px;
    cursor: pointer;
    transition: background-color .3s ease;
    position: absolute;
    bottom:1.5rem;
    left: calc(50% - 35px);
    border: 1px solid #4e82af52;
  }

  .toggle-button{
    width: 30px;
    height: 30px;
    background-color: #874B9E;
    border-radius: 50%;
    transition: transform 0.3s ease;
    display: none;
    }

    .toggle-container.toggled{
        justify-content: flex-end;
        
    }

    .creator-label{
        position: absolute;
        bottom: 1.25rem;
        display: none;
        left: calc(50% - 100px);
    }
    .preview-label{
        position: absolute;
        bottom: 1.25rem;
        right: calc(50% - 100px);
        display: none;
    }
    
    

  @media (orientation: portrait){
    .Character-Creator{
      width: calc(100vw - 2rem);
    }
    .Character-Creator-Output-Section{
        display: none;
        width: 100%;
    }
    .saveCharacterButton{
        right:1rem;
        height: 3rem;
      }
      .backToDashboardButton{
        left:1rem;
        height: 3rem;
      }
    .Character-Creator-Input-Section{
        width: 100%;
        border: none;
    }
    .Character-Creator-Input-Section-Div{
        width: calc(100% - 5.25rem);
        height: calc(100% - 8.5rem);
        max-width: unset;
       
    }
    
    
    .Character-Creator-Output-Preview{
        height: 37rem
    }
    .toggle-container {
        display: flex;
      }
    
      .toggle-button{
        display: block;
        }
    
        .creator-label{
            display: block;
        }
        .preview-label{
            display: block;
        }
    .Button-Container{
        flex-direction: row;
        width: 100vw;
      }
  }
  @media (orientation: landscape){
    .Character-Creator-Output-Section{
        display: block !important;
    }
    .Character-Creator-Input-Section{
        display: flex !important;
    }
    
  }
  @media (max-width:400px){
    .Button-Container{
      flex-direction: column;
      width: calc(100vw - 7.25rem);
    }
    .Character-Negative-Input{
      display: none;
    }
    .Token-Container{
      transform: translateX(.2rem);
    }
    .Tokens-Icon{
      margin: 0px;
    }
  }