.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #E0ECFC;
  padding-top: 150px;
  color: #4F5962;
  background-size: cover;
}

.Product-Title {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  flex-grow: 1; 
  text-align: right;
  cursor:pointer;
}

.App-body{
  background-color: #E0ECFC;
  display:flex;
  justify-content: center;
  min-height: calc(100vh - 170px);
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
}

.App-link {
  color: #61dafb;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}



.Generate-Image-Button:disabled{
  cursor:not-allowed;
  background-color: #D3D5D7;
  border-color:#D3D5D7;
}


